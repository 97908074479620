/**
 * TopNavigation Component
 *
 * This component is responsible for The top navigation with all the categories
 *
 * @category   GameBrowser Item
 * @author     ggr <gerjo@madmenmt.com>
 * @copyright  2021 MocinoPlay
 * @version    Release: @package_version@
 * @since      Component available since Release 1.0.0
 */
import cx from "classnames";
import React from "react";
import Link from "../../../Link";
import Icon from "../../../Icon";
import { scrollTo } from "../../../../core/utils";

const Item = ({
  label,
  onClick = null,
  icon = null,
  active = false,
  to = "",
  params = {},
  className,
  children,
  subcategory = false,
  id,
  ref,
  trackName,
  scroll,
  additionalScroll = false,
  trackParams,
}) => {
  const click = () => {
    Promise.all([onClick()]).then((result) => {
      scrollTo(window, 1000, {
        scrollTo: scroll || "#prevGameBrowser",
        additional: additionalScroll,
      });
    });
    // onClick();
    // console.log(`#subcategory-${id}`)
    // scrollTo(window, 1000, { scrollTo: id ? `#subcategory-${id.replace('|','-')}` : '#game-browser-navigation' });
  };
  return (
    <Link
      to={to}
      onClick={click}
      transition={false}
      params={params}
      ref={ref}
      id={id}
      className={cx(
        "px-5 rtl:mr-0 rtl:ml-3 mr-3 font-bold rounded-3xl hover:bg-gold hover:text-black flex items-center gap-2 justify-center whitespace-nowrap group",
        {
          "bg-brand-600 text-brand-500": !active,
          "bg-gold text-black": active,
          "py-2 text-16 mb-1": !subcategory,
          "py-1 text-14 h-7": subcategory,
        }
      )}
      trackName={trackName}
      trackParams={trackParams}
    >
      {icon && (
        <Icon
          icon={icon}
          className={cx(
            "w-5 h-5 group-hover:text-black  ",
            {
              "text-brand-500": !active,
              "text-black": active,
            },
            className
          )}
        />
      )}
      <span>{label}</span>
    </Link>
  );
};

export default Item;
