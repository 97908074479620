import {Generic, JSONLD} from "react-structured-data";
import {Helmet} from "react-helmet-async";
import React from "react";
import HomePublic from "./HomePublic";
import HomeAuthenticated from "./HomeAuthenticated";

import {useAuth} from "@components/Provide/AuthContext";

const Home = () => {
  const { isAuthenticated } = useAuth();

  console.log('rerender ', isAuthenticated);

  return isAuthenticated ? (
    <HomeAuthenticated />
    // <MyClubhouseAuthenticated />
  ) : (
    <>
      <JSONLD dangerouslyExposeHtml>
        <Generic
          type="website"
          jsonldtype="WebSite"
          schema={{
            url: `https://theclubhousecasino.com`,
            potentialAction: {
              "@type": "SearchAction",
              target: `https://theclubhousecasino.com?q={search_term_string}`,
              "query-input": "required name=search_term_string"
            }
          }}
        />
      </JSONLD>
      <JSONLD dangerouslyExposeHtml>
        <Generic
          type="organization"
          jsonldtype="Organization"
          schema={{
            url: `https://theclubhousecasino.com`,
            logo: "https://theclubhousecasino.com/img/general-logo.png"
          }}
        />
      </JSONLD>
      <JSONLD dangerouslyExposeHtml>
        <Generic
          type="contactpoint"
          jsonldtype="ContactPoint"
          schema={{
            contactPoint: [
              {
                "@type": "ContactPoint",
                url: "https://theclubhousecasino.com/faq",
                contactType: "customer service"
              }
            ]
          }}
        />
      </JSONLD>
      <HomePublic />
    </>
  );
};

export default Home;
