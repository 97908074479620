import React, { useEffect, useState } from "react";
// import CentrifugeReact from "../../../../core/centrifuge";
import cx from "classnames";
import { useIntl } from "react-intl";
// import useSWR from "swr";
// import useSWRImmutable from "swr/immutable";
import Button from "@components/Button";
import { useQuery } from "@apollo/client";
import InfiniteScroll from "react-infinite-scroller";
import GameItem from "../../GameItem";
// import { useCentrifuge } from "@components/Provide/CentrifugeContext";
import Games from "../../Games";
import { useDevice } from "../../../Provide/DeviceContext";
import // getAllowedGames,
// getJackpots,
// getProviders,
// getTotalJackpots,
"../../../../data/model/casino";
import { useRouter } from "../../../Provide/RouterContext";
import { useWallet } from "../../../Provide/WalletContext";
// import Cookies from "universal-cookie";
import FeaturedTournament from "../../../Tournaments/FeaturedTournament";
import Icon from "../../../Icon/Icon";
import LandingCasinoHeader from "./LandingCasinoHeader";
import getSoftSwissGamesQuery from "../../get-softswiss-games.graphql";
import { useAuth } from "../../../Provide/AuthContext";
import useSession from "../../../../hooks/useSession";

const LandingCasinoCategory = ({
  collection,
  collections,
  selected,
  subSelected,
  subcategory,
  setSubSelected,
  allTournaments,
  recentGames,
}) => {
  const intl = useIntl();
  const [expand, setExpand] = useState(false);
  const [items, setItems] = useState([]);
  const [page, setPage] = useState(1);
  const { isMobile } = useDevice();
  const router = useRouter();
  const { currency } = useWallet();
  const { isAuthenticated } = useAuth();
  const { userCountry } = useSession();

  useEffect(() => {
    setItems([]);
  }, [collection?.fullId]);

  console.log("vars to retrieve", {
    device: isMobile?.any ? "mobile" : "desktop",
    currencies: [currency],
    categories: [subcategory?.fullId],
    page,
  });

  const [id] = subcategory?.id?.split(":") ?? [];
  const [filterGroup] = id?.split?.("~") ?? [];

  console.log("subcategory?.id", subcategory?.id);
  console.log("id?.split", id);
  console.log("filterGroup", filterGroup);
  const { loading, data } = useQuery(getSoftSwissGamesQuery, {
    ssr: false,
    skip: filterGroup === "home|recently_played",
    variables: {
      device: isMobile?.any ? "mobile" : "desktop",
      currencies: [currency],
      categories: [subcategory?.fullId],
      page,
      isAuthenticated,
      userCountry
    },
    onCompleted: (res) => {
      if (subcategory?.fullId && filterGroup !== "home|recently_played") {
        if (data?.getSoftSwissGames?.pagination?.current_page == 1) {
          setItems(res?.getSoftSwissGames?.games ?? []);
        } else {
          setItems([...items, ...(res?.getSoftSwissGames?.games ?? [])]);
        }
      }
    },
  });

  console.log("filterGroupIncoming", subcategory);

  useEffect(() => {
    if (filterGroup === "home|recently_played") {
      setItems(recentGames);
    }
  }, [filterGroup, recentGames]);

  const maxItems = {
    s1: 10,
    s2: 6,
    s3: 10,
    s4: 14,
  };

  const itemsPerPage = 100;

  const widthMobile = {
    s1: "172px",
    s2: "91vw",
    s3: "44.5vw",
    s4: "29vw",
  };

  const loadMore = (page) => {
    // const newPages = {...pages, [subcategoryId]:page};
    if (!loading) {
      setPage(page + 1);
    }
  };

  if (subcategory?.id === "home|tournament") {
    return (
      <>
        <section id={subcategory?.id}>
          <LandingCasinoHeader
            collection={collection}
            collections={collections}
            subcategory={subcategory}
            setSubSelected={setSubSelected}
            subSelected={subSelected}
            selected={selected}
          />
          <FeaturedTournament allTournaments={allTournaments} />
        </section>
      </>
    );
  }

  const size = subcategory?.size;

  console.log("list from lobby", subSelected?.fullId, items);
  return (
    items?.length > 0 && (
      <>
        <section id={subcategory?.id}>
          <LandingCasinoHeader
            collection={collection}
            collections={collections}
            subcategory={subcategory}
            setSubSelected={setSubSelected}
            subSelected={subSelected}
            selected={selected}
          />

          {isMobile?.phone ? (
            <InfiniteScroll
              loadMore={loadMore}
              hasMore={
                expand &&
                data?.getSoftSwissGames?.pagination?.total_pages >= page
              }
              loader={<div>Loading</div>}
              useWindow
            >
              <div className={expand ? "" : "flex overflow-auto mt-2"}>
                <div
                  className={
                    expand
                      ? "grid grid-cols-2 md:grid-cols-5 gap-2.5 md:gap-8 mt-5"
                      : "inline-flex my-2"
                  }
                >
                  {items.map((item, index) => (
                    <GameItem
                      // lobby={lobby}
                      // maincategory={selected}
                      // subcategory={subSelected}
                      // jackpot={jackpots?.[currency]?.[item.id]}
                      game={item}
                      key={item.id}
                      // showGameLabel={isMobile.any}
                      className={
                        ("m-auto ",
                        index !== items?.length - 1 && !expand && "mr-2")
                      }
                      style={{
                        width: expand ? "100%" : widthMobile[size],
                      }}
                      size={expand && size === "s2" ? "s3" : size}
                    />
                  ))}
                </div>
              </div>
            </InfiniteScroll>
          ) : (
            <InfiniteScroll
              loadMore={loadMore}
              hasMore={
                expand &&
                data?.getSoftSwissGames?.pagination?.total_pages >= page
              }
              loader={<div>Loading</div>}
              useWindow
            >
              <div
                className={cx("grid  gap-2.5 md:gap-8 mt-5", {
                  "grid-cols-1 md:grid-cols-3": size === "s2",
                  "grid-cols-2 md:grid-cols-5": size === "s3",
                  "grid-cols-2 md:grid-cols-7": size === "s4",
                  "w-auto": expand,
                })}
              >
                {items
                  ?.slice(0, expand ? itemsPerPage * page : maxItems[size])
                  .map((item) => (
                    <GameItem
                      // lobby={lobby}
                      // maincategory={selected}
                      // subcategory={subSelected}
                      // jackpot={jackpots?.[currency]?.[item.id]}
                      game={item}
                      key={item.id}
                      // showGameLabel={isMobile.any}
                      size={size}
                    />
                  ))}
              </div>
            </InfiniteScroll>
          )}

          {isMobile.any && (
            <div className="flex items-center justify-end mt-5">
              <Button.Action
                trackName="Action"
                trackParams={[
                  router?.routename,
                  `Show More - ${subcategory?.label}`,
                ]}
                arrow={expand ? "up" : "down"}
                onClick={() => {
                  setExpand((previousState) => !previousState);
                }}
              >
                {expand
                  ? intl.formatMessage({
                      defaultMessage: "Show less",
                      description: "Label",
                    })
                  : intl.formatMessage({
                      defaultMessage: "Show all",
                      description: "Label",
                    })}
              </Button.Action>
            </div>
          )}

          {items.length > maxItems[size] && !isMobile.any && (
            <div className="flex items-center justify-end mt-5">
              <Button.Action
                trackName="Action"
                trackParams={[
                  router?.routename,
                  `Show More - ${subcategory?.label}`,
                ]}
                arrow={expand ? "up" : "down"}
                onClick={() => {
                  setExpand((previousState) => !previousState);
                }}
              >
                {expand
                  ? intl.formatMessage({
                      defaultMessage: "Show less",
                      description: "Label",
                    })
                  : intl.formatMessage({
                      defaultMessage: "Show all",
                      description: "Label",
                    })}
              </Button.Action>
            </div>
          )}
        </section>
      </>
    )
  );
};
export default LandingCasinoCategory;
