import React, { useState, useEffect } from "react";
import cx from "classnames";
import Button from "@components/Button";
import { useIntl } from "react-intl";
import ProviderItem from "../../GameBrowserNavigation/ProviderItem/ProviderItem";
import GameItem from "../../GameItem/GameItem";
import { useDevice } from "../../../Provide/DeviceContext";
import { useWallet } from "../../../Provide/WalletContext";
import { scrollTo } from "../../../../core/utils";
import getProvidersQuery from "./get-providers.graphql";
import { useLazyQuery, useQuery } from "@apollo/client";
import getSoftSwissProvidersQuery from "../../get-softswiss-providers.graphql";
import getGameListByProviderQuery from "../../get-softswiss-games.graphql";
import InfiniteScroll from 'react-infinite-scroller';
import { useAuth } from "../../../Provide/AuthContext";
import useSession from "../../../../hooks/useSession";

const ProvidersList = ({}) => {
  const { currency } = useWallet();
  const { isMobile } = useDevice();
  const { isAuthenticated } = useAuth();
  const [selectedProvider, setSelectedProvider] = useState("");
  const [selectedProviderGameList, setSelectedProviderGameList] = useState([]);
  const [totalResults, setTotalResults] = useState();
  const [page, setPage] = useState(1);
  const { userCountry } = useSession();

  useEffect(() => {
    setSelectedProviderGameList([]);
  },[selectedProvider]);

  const {
    error: providersError,
    loading: loadingProviders,
    data: providerslist,
  } = useQuery(getSoftSwissProvidersQuery, {
    variables: {
      isAuthenticated,
      userCountry
    },
  });

  const [getGameListByProvider, { loading: getGameListLoading, data }] = useLazyQuery(
    getGameListByProviderQuery,
    {
      onCompleted: (data) => {
        console.log('data?.getSoftSwissGames?',data?.getSoftSwissGames);

        if (data?.getSoftSwissGames?.pagination?.current_page == 1) {
          setSelectedProviderGameList(data?.getSoftSwissGames?.games ?? []);
        } else {
          setSelectedProviderGameList([...selectedProviderGameList, ...data?.getSoftSwissGames?.games ?? []]);
        }

        // setSelectedProviderGameList(data?.getSoftSwissGames?.games);
        setTotalResults(data?.getSoftSwissGames?.pagination?.total_count);
      },
      onError: (error) => {
        console.log("game list by providers --error", error);
      },
    }
  );

  const onSelectProvider = (selectedProviderId) => {
    setSelectedProvider(selectedProviderId);
    if (selectedProviderId) {
      // setSelectedProviderGameList([]);
      getGameListByProvider({
        variables: {
          device: isMobile?.any ? "mobile" : "desktop",
          providers: [selectedProviderId],
          currencies: [currency],
          userCountry,
          page,
          isAuthenticated
        },
      });
    }
  };

  const intl = useIntl();
  const [expand, setExpand] = useState([]);

  const loadMore = (page) => {
    // const newPages = {...pages, [subcategoryId]:page};
    console.log("getGameListLoading", getGameListLoading);
    if(!getGameListLoading) {
      setPage(page+1);
      getGameListByProvider({
        variables: {
          device: isMobile?.any ? "mobile" : "desktop",
          providers: [selectedProvider],
          currencies: [currency],
          page: page+1,
          userCountry,
          isAuthenticated
        },
      });
    }
  }
  const { data: providers } = useQuery(getProvidersQuery, {
    variables: { psection: "Game Provider" },
  });

  const filteredDataOfCockpit = providers?.ProvidersBySection?.filter((item) =>
    providerslist?.getSoftSwissProviders?.find(
      (provider) => provider.id === item.softswiss_id
    )
  );

  console.log('getGameListByProvider?.getSoftSwissGames?.pagination?.total_pages >= page', data?.getSoftSwissGames?.pagination?.total_pages);
  return (
    <>
      <div className="grid grid-cols-2 md:grid-cols-5 gap-4 md:gap-8 mt-5">
        <>
          {filteredDataOfCockpit?.map((item) => (
            <ProviderItem
              trackName="Provider"
              trackParams={{ component: "ProvidersList", item: item }}
              item={item}
              // id={item.name}
              className={cx(
                item.softswiss_id !== selectedProvider &&
                  selectedProvider !== ""
                  ? "hidden"
                  : ""
              )}
              active={selectedProvider === item.softswiss_id}
              onClick={() => {
                scrollTo(window, 1000, { scrollTo: "#gameBrowser" });
                onSelectProvider(
                  selectedProvider === "" ||
                    selectedProvider !== item.softswiss_id
                    ? item.softswiss_id
                    : ""
                );
              }}
            />
          ))}
        </>
      </div>

      {selectedProvider !== "" && (
        <>
          {!getGameListLoading && selectedProviderGameList?.length > 0 ? (
            <>
              <section id={selectedProvider}>
                <div className="text-white mt-2">
                  {totalResults}{" "}
                  {intl.formatMessage({
                    defaultMessage: "Games found",
                    description: "Label",
                  })}
                </div>
                <InfiniteScroll

                    loadMore={loadMore}
                    hasMore={expand?.[selectedProvider] && data?.getSoftSwissGames?.pagination?.total_pages >= page}
                    loader={
                      <div>
                        Loading
                      </div>
                    }
                    useWindow
                >
                <div className="grid grid-cols-2 md:grid-cols-5 gap-2.5 md:gap-8 mt-5">
                  {selectedProviderGameList
                    ?.slice(0, expand?.[selectedProvider] ? 1000000 : 10)
                    .map((item) => (
                      <GameItem
                        game={item}
                        key={item?.id}
                        showGameLabel={isMobile.any}
                      />
                    ))}
                </div>
                </InfiniteScroll>


                {selectedProviderGameList.length > 10 && (
                  <div className="mt-5 flex items-center justify-end">
                    <Button.Action
                      trackName="Show More"
                      trackParams={{ component: "ProvidersList" }}
                      arrow={expand?.[selectedProvider] ? "up" : "down"}
                      onClick={() => {
                        setExpand((previousState) => {
                          const newState = [...previousState];
                          newState[selectedProvider] =
                            !previousState?.[selectedProvider];

                          return newState;
                        });
                      }}
                    >
                      {expand?.[selectedProvider]
                        ? intl.formatMessage({
                            defaultMessage: "Show less",
                            description: "Label",
                          })
                        : intl.formatMessage({
                            defaultMessage: "Show all",
                            description: "Label",
                          })}
                    </Button.Action>
                  </div>
                )}
              </section>
            </>
          ) : getGameListLoading ? (
            <div className="text-white mt-2">
              {intl.formatMessage({
                defaultMessage: "Loading....",
                description: "Loading....",
              })}
            </div>
          ) : (
            <div className="text-white mt-2">{intl.formatMessage({
              defaultMessage: "No Games found..",
              description: "No Games found..",
            })}</div>
          )}
        </>
      )}
    </>
  );
};

export default ProvidersList;
