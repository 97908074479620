/**
 * TopNavigation Component
 *
 * This component is responsible for The top navigation with all the categories
 *
 * @category   GameBrowser Item
 * @author     ggr <gerjo@madmenmt.com>
 * @copyright  2021 MocinoPlay
 * @version    Release: @package_version@
 * @since      Component available since Release 1.0.0
 */
import cx from "classnames";
import React from "react";
import Link from "../../../Link/Link";
import Icon from "../../../Icon/Icon";
import { cfImgUrl } from "../../../../config";

const ProviderItem = ({
  onClick = null,
  active = false,
  to = "",
  params = {},
  className,
  subcategory = false,
  item,
  trackName,
  trackParams,
}) => {
  const click = () => {
    Promise.all([onClick()]).then((result) => {
      // scrollTo(window, 1000, { scrollTo: id ? `#subcategory-${id.replace('|','-')}` : '#game-browser-navigation' });
    });
    // onClick();
    // console.log(`#subcategory-${id}`)
    // scrollTo(window, 1000, { scrollTo: id ? `#subcategory-${id.replace('|','-')}` : '#game-browser-navigation' });
  };
  return (
    <Link
      to={to}
      onClick={click}
      transition={false}
      params={params}
      className={cx(
        "px-5 overflow-hidden font-bold rounded-lg hover:bg-gold hover:text-black flex items-center justify-center whitespace-nowrap group w-full h-40 relative ",
        {
          "bg-brand-600 text-brand-500": !active,
          "bg-gold text-black": active,
          "py-2 text-16": !subcategory,
          "py-1 text-14 ": subcategory,
        },
        className
      )}
      trackName={trackName}
      trackParams={trackParams}
    >
      {active && (
        <div className="h-5 w-5 mt-2 mr-2 rounded-full bg-brand-600 absolute right-0 top-0 transform hover:scale-110">
          <Icon
            icon="close"
            className="fill-current h-3 w-3 text-white m-auto mt-1"
          />
        </div>
      )}

      <img
        src={
          item?.image?.path?.includes(".svg") || !cfImgUrl
            ? `${COCKPITCFG.url}/${item?.image?.path}`
            : `${cfImgUrl}/format=auto,dpr=2,onerror=redirect/${COCKPITCFG.url}/${item?.image?.path}`
        }
        className={cx("h-15 w-auto m-auto max-h-36")}
        onError={(e) => {
          e.target.onerror = null;
          e.target.src = `https://cdn2.softswiss.net/logos/providers/white/${item?.softswiss_id}.svg`;
        }}
      />

      {/* <span> */}
      {/*  {label} */}
      {/* </span> */}
    </Link>
  );
};

export default ProviderItem;
