import React from "react";
import GameBrowser from "@components/GameBrowser";
import LandingOffer from "../../components/LandingOffer/LandingOffer";

class HomeAuthenticated extends React.PureComponent {
  render() {
    return (
      <>
        {/* <LandingOffer/> */}
        <GameBrowser section="LandingCasino" menu="default" align="center" />
        {/* <GridSlider /> */}
        {/* <Tournament /> */}
      </>
    );
  }
}

export default HomeAuthenticated;
