import React, { useEffect, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import useSWR from "swr";
import InfiniteScroll from "react-infinite-scroller";
import { useTracking } from "@hooks/useTracking";
import GameItem from "../../GameItem";
import { useDevice } from "../../../Provide/DeviceContext";
import {
  getBackendOrderedGames,
  getProviders,
} from "../../../../data/model/casino";
import { useWallet } from "../../../Provide/WalletContext";
import { useAuth } from "../../../Provide/AuthContext";
import ProvidersList from "../ProvidersList";

let debounceTimer;
const debounce = (callback, time) => {
  window.clearTimeout(debounceTimer);
  debounceTimer = window.setTimeout(callback, time);
};

const SearchResults = ({
  collection,
  collections,
  activeSearch,
  query,
  selected,
}) => {
  const intl = useIntl();
  const { currency } = useWallet();
  const { isMobile } = useDevice();
  const { isAuthenticated } = useAuth();
  const { track } = useTracking();
  const subCategory = { fullId: "all", id: "all", originalId: "All" };
  // subCategory is set to all as it is only for search
  const {
    error,
    data: allGamesWithoutCurrFilter,
    isValidating: isLoadingGames,
  } = useSWR(
    ["getBackendOrderedGames", isMobile?.any, subCategory],
    getBackendOrderedGames
  );

  console.log("allGamesWithoutCurrFilter", allGamesWithoutCurrFilter);
  const allGames = allGamesWithoutCurrFilter?.filter(
    (item) => item?.real?.[currency]
  );

  const { data: providerslist } = useSWR("getProviders", getProviders);
  const [providerFilter, setProviderFilter] = useState("");
  const numGamesPerTime = 200;
  const [numGames, setNumGames] = useState(numGamesPerTime);
  // const { error:allgamesError, data: allAllowedGames = [] } = useSWR(
  //   ['getAllowedGames', isMobile?.any],
  //   (url, isMobile) => getAllowedGames(isMobile),
  // );
  const getAllGames = allGames?.filter?.((item) =>
    item?.collections?.includes(subCategory.id)
  );

  const allGamesWithCurrency = [];
  const listFiltered = getAllGames?.map((item) => {
    allGamesWithCurrency.push(item);
  });

  useEffect(() => {
    refilterProvidersList();
  }, [providerslist, selected]);

  // to remove providers which are empty
  const refilterProvidersList = () => {
    const providersWithGames = [];
    providerslist?.map((provider) => {
      const providersList = [];
      const provs = allGames?.map((item) => {
        item?.provider
          ?.toLowerCase()
          ?.includes(provider?.title?.toLowerCase()) &&
          item?.real?.[currency] &&
          providersList.push(item);
      });

      if (providersList?.length > 0) {
        providersWithGames.push(provider);
      }
    });
    return providersWithGames;
  };

  const searchResult = useMemo(
    (params) => {
      console.log("params", params, query);
      if (selected === "providers") {
        const provList = refilterProvidersList();
        let result;
        if (!query || query?.length < 3) return "";
        result = provList?.filter(
          (game) =>
            game?.id?.toLowerCase()?.includes(query?.toLowerCase()) ||
            game?.provider?.toLowerCase()?.includes(query?.toLowerCase())
        );
        return result;
      }

      console.log("query", query);

      debounce(() => {
        track("Search games", {
          query,
        });
      }, 1000);

      if (!query || query?.length < 3) return "";
      const result = allGamesWithCurrency.filter(
        (game) =>
          game?.name?.toLowerCase()?.includes(query?.toLowerCase()) ||
          game?.provider?.toLowerCase()?.includes(query?.toLowerCase())
      );

      return result;
    },
    [allGames, query]
  );

  const loadMore = () => {
    setNumGames(numGames + numGamesPerTime);
  };

  console.log("searchResult", searchResult);
  return (
    <>
      <h3 className="flex flex-1 mb-5 font-light text-white capitalize text-24">
        {intl.formatMessage({
          defaultMessage: "Search Result",
          description: "Header",
        })}
      </h3>
      {selected === "providers" ? (
        <>
          <ProvidersList
            activeSearch={activeSearch}
            providerFilter={providerFilter}
            setProviderFilter={setProviderFilter}
            providerItem={searchResult}
          />
        </>
      ) : (
        <div className="w-full">
          {typeof searchResult === "string" ? (
            <p className="text-white">
              {intl.formatMessage({
                defaultMessage:
                  "Please type at least 3 characters to start your search",
                description: "Label of inputfield",
              })}
            </p>
          ) : (
            <>
              {searchResult?.length > 0 ? (
                <>
                  <InfiniteScroll
                    className="grid grid-cols-2 md:grid-cols-5 gap-2.5 md:gap-8 mt-3 md:mt-4"
                    loadMore={loadMore}
                    hasMore={numGames < getAllGames?.length}
                    loader={<div>loading</div>}
                    useWindow
                  >
                    {searchResult.slice(0, numGames)?.map((item) => (
                      <GameItem
                        game={item}
                        key={item.id}
                        showGameLabel={isMobile.any}
                      />
                    ))}
                  </InfiniteScroll>
                </>
              ) : isLoadingGames ? (
                <>
                  <p className="flex flex-1 mb-5 font-light text-white capitalize text-24 justify-center">
                    {intl.formatMessage({
                      defaultMessage: "Loading....",
                      description: "Loading Text on search result",
                    })}
                  </p>
                </>
              ) : (
                <>
                  <p className="w-full text-white">
                    {intl.formatMessage({
                      defaultMessage:
                        "I am afraid there are no results for that game. If you would like to request we add it, please email",
                      description: "Label of inputfield",
                    })}{" "}
                    <a
                      className="text-gold"
                      href="mailto:freddie@theclubhousecasino.com"
                      target="#blank"
                    >
                      freddie@theclubhousecasino.com
                    </a>
                  </p>
                </>
              )}
            </>
          )}
        </div>
      )}
    </>
  );
};
export default SearchResults;
