import React, { useCallback, useEffect, useState } from "react";
import slugify from "slugify";
import { Field } from "formik";
import cx from "classnames";
import { useIntl } from "react-intl";
import Item from "./Item";
import Icon from "../../Icon";
import { useModal } from "../../Modal/ModalContext";
import Games from "../Games";
import { useRouter } from "../../Provide/RouterContext";
import { useSelector } from "react-redux";
import { scrollTo } from "../../../core/utils";
import Cookies from "universal-cookie";
import { useDevice } from "../../Provide/DeviceContext";
import { useQuery } from "@apollo/client";
import GET_TRANSLATED_CATEGORIES from "./get-translated-categories.graphql";

function useScrollDirection() {
  const [scrollDirection, setScrollDirection] = useState(null);

  useEffect(() => {
    let lastScrollY = window.pageYOffset;

    const updateScrollDirection = () => {
      const scrollY = window.pageYOffset;
      const direction = scrollY > lastScrollY ? "down" : "up";
      if (
        direction !== scrollDirection &&
        (scrollY - lastScrollY > 5 || scrollY - lastScrollY < -5)
      ) {
        setScrollDirection(direction);
      }
      lastScrollY = scrollY > 0 ? scrollY : 0;
    };
    window.addEventListener("scroll", updateScrollDirection); // add event listener
    return () => {
      window.removeEventListener("scroll", updateScrollDirection); // clean up
    };
  }, [scrollDirection]);

  return scrollDirection;
}

const GameBrowserNavigation = ({
  collection,
  collections,
  providers,
  menuItems,
  selected,
  setSelected,
  subSelected,
  setSubSelected,
  setFieldValue,
  align,
  query,
}) => {
  const intl = useIntl();
  const router = useRouter();
  const scrollDirection = useScrollDirection();
  const { isMobile } = useDevice();

  const { loading, error, data } = useQuery(GET_TRANSLATED_CATEGORIES, {
    variables: { locale: intl.locale },
  });

  const labels = {
    crypto: intl.formatMessage({
      defaultMessage: "Crypto",
      description: "Label",
    }),
    home: intl.formatMessage({
      defaultMessage: "Casino Lobby",
      description: "Label",
    }),
    slots: intl.formatMessage({
      defaultMessage: "Slots",
      description: "Label",
    }),
    live: intl.formatMessage({
      defaultMessage: "Live Casino",
      description: "Label",
    }),
    table: intl.formatMessage({
      defaultMessage: "Table Games",
      description: "Label",
    }),
    "home|recommended": intl.formatMessage({
      defaultMessage: "Recommended",
      description: "Label",
    }),
    "home|trending": intl.formatMessage({
      defaultMessage: "Trending",
      description: "Label",
    }),
    "home|recently_played": intl.formatMessage({
      defaultMessage: "Recently Played",
      description: "Label",
    }),
    "home|gamesofthemonth": intl.formatMessage({
      defaultMessage: "Games of the Month",
      description: "Games of the Month Label",
    }),
    "home|gamesofthemonth~s2": intl.formatMessage({
      defaultMessage: "Games of the Month",
      description: "Games of the Month Label",
    }),
    "home|new": intl.formatMessage({
      defaultMessage: "Latest Releases",
      description: "Label",
    }),
    "home|featured_slots": intl.formatMessage({
      defaultMessage: "Featured Slots",
      description: "Label",
    }),
    "home|tournament": intl.formatMessage({
      defaultMessage: "Tournaments Menu",
      description: "Label",
    }),
    "home|classic_live": intl.formatMessage({
      defaultMessage: "Classic Live Games",
      description: "Label",
    }),
    "home|jackpot": intl.formatMessage({
      defaultMessage: "Jackpot Games",
      description: "Label",
    }),
    "home|lottery": intl.formatMessage({
      defaultMessage: "Lottery",
      description: "Label",
    }),
    "crypto|Bitcoin": intl.formatMessage({
      defaultMessage: "Bitcoin Games",
      description: "Label",
    }),
    "crypto|ethereum": intl.formatMessage({
      defaultMessage: "Ethereum Games",
      description: "Label",
    }),
    "crypto|Livecrypto": intl.formatMessage({
      defaultMessage: "Live Crypto",
      description: "Label",
    }),
    "crypto|Litecoin": intl.formatMessage({
      defaultMessage: "Litecoin Games",
      description: "Label",
    }),
    "live|popular": intl.formatMessage({
      defaultMessage: "Popular Live Games",
      description: "Label",
    }),
    "live|roulette": intl.formatMessage({
      defaultMessage: "Roulette",
      description: "Label",
    }),
    "live|blackjack": intl.formatMessage({
      defaultMessage: "Blackjack",
      description: "Label",
    }),
    "live|gameshows": intl.formatMessage({
      defaultMessage: "Game Shows",
      description: "Label",
    }),
    "live|baccarat": intl.formatMessage({
      defaultMessage: "Baccarat",
      description: "Label",
    }),
    "live|poker": intl.formatMessage({
      defaultMessage: "Poker",
      description: "Label",
    }),
    "live|dice": intl.formatMessage({
      defaultMessage: "Dice Games",
      description: "Label",
    }),
    "live|all": intl.formatMessage({
      defaultMessage: "All Live",
      description: "Label",
    }),
    "live|card": intl.formatMessage({
      defaultMessage: "Card Games",
      description: "Label",
    }),
    "slots|recommended": intl.formatMessage({
      defaultMessage: "Recommended Slots",
      description: "Label",
    }),
    "slots|popular": intl.formatMessage({
      defaultMessage: "Popular Slots",
      description: "Label",
    }),
    "slots|megaways": intl.formatMessage({
      defaultMessage: "Megaways",
      description: "Label",
    }),
    "slots|classic": intl.formatMessage({
      defaultMessage: "Classic slots",
      description: "Label",
    }),
    "slots|jackpots": intl.formatMessage({
      defaultMessage: "Jackpots",
      description: "Label",
    }),
    "slots|drops_and_wins": intl.formatMessage({
      defaultMessage: "Drops and Wins",
      description: "Label",
    }),
    "slots|all": intl.formatMessage({
      defaultMessage: "All Slots",
      description: "Label",
    }),
    "table|roulette": intl.formatMessage({
      defaultMessage: "Roulette",
      description: "Label",
    }),
    "table|blackjack": intl.formatMessage({
      defaultMessage: "Blackjack",
      description: "Label",
    }),
    "table|baccarat": intl.formatMessage({
      defaultMessage: "Baccarat",
      description: "Label",
    }),
    "table|video_poker": intl.formatMessage({
      defaultMessage: "Video Poker",
      description: "Label",
    }),
    all: intl.formatMessage({
      defaultMessage: "All games",
      description: "Label",
    }),
  };

  const getCategoryLabel = useCallback(
    (feCategoryID) => {
      const translatedCategory = data?.getTranslatedCategories?.find(
        (x) => x.feCategoryID === feCategoryID
      );
      return translatedCategory?.translatedCategory
        ? translatedCategory?.translatedCategory
        : "";
    },
    [data]
  );

  const { showModal } = useModal();
  const [activeField, setActiveField] = useState(false);
  const [previousCollection, setPreviousCollection] = useState("");
  const isNavigationOpen = useSelector(
    (state) => state.navigation.isNavigationOpen
  );

  const cookies = new Cookies();

  const handleSelectPreviousCollection = () => {
    const previousCollectionDetails = previousCollection
      ? previousCollection
      : collections[0];

    if (previousCollectionDetails) {
      setFieldValue("collection", previousCollectionDetails),
        setSelected(previousCollectionDetails?.id),
        setSubCategory(
          previousCollectionDetails?.id,
          previousCollectionDetails?.children?.[0]?.id
        ),
        setSubSelected(previousCollectionDetails?.children?.[0]?.label);
    }
  };

  const setSubCategory = (id, subId) => {
    setFieldValue("activeSearch", false);
    // setSubSelected(subId);
    // if (id !== 'live') {
    console.log("slide to subId", subId);
    const categorySelected = cookies.get("gameCategory");
    // window.setTimeout(() => {

    const [scrollid] = subId?.split(":") ?? [];
    const [filterGroup] = scrollid?.split?.("~") ?? [];

    scrollTo(window, 1000, {
      scrollTo: filterGroup
        ? `#subcategory-${filterGroup.replace("|", "-")}`
        : isMobile.any
        ? "#search-navigation"
        : "#game-browser-navigation",
    });
    // }, 500)
    // }
  };

  const handleFocus = () => {
    if (isMobile.any) {
      if (align === "center" && typeof window !== "undefined") {
        setTimeout(() => {
          scrollTo(window, 3000, {
            scrollTo: isMobile.any
              ? "#game-browser-navigation"
              : "#search-navigation",
            calcTopNavigation: false,
          });
        }, 200);
      } else {
        scrollTo(window, 3000, {
          scrollTo: "#gameBrowser",
          calcTopNavigation: false,
        });
      }
    }
    if (isMobile.any) {
      setActiveField(true);
    }
    if (query?.length >= 3) {
      //setSelected(selected !== 'providers' ? 'all' : 'providers');
      setPreviousCollection(collection);
      setSelected("all");
      setSubSelected("");
      setActiveField(true);

      router.routename === "liveLobby" && router.setRoute("live");
      router.routename === "cryptoLobby" && router.setRoute("crypto");
      // setSubCategory(collections?.[0]?.id, collections?.[0]?.children?.[0]?.id);
      setFieldValue("activeSearch", true);
    }
  };

  const handleBlur = (e) => {
    setActiveField(false);
  };

  useEffect(() => {
    if (query?.length >= 3) {
      handleFocus();
    }
  }, [query]);

  useEffect(() => {
    if (router?.currentRoute?.name === "liveLobby") {
      setFieldValue("collection", { id: "live", originalId: "live" });
      setSelected("live");
      const parent = collections?.find((item) => item?.id === "live");
      setSubCategory(parent?.id, parent?.children?.[0]?.id);
    } else if (router?.currentRoute?.name === "cryptoLobby") {
      setFieldValue("collection", { id: "crypto", originalId: "crypto" });
      setSelected("crypto");
      const parent = collections?.find((item) => item?.id === "crypto");
      setSubCategory(parent?.id, parent?.children?.[0]?.id);
    } else if (router?.currentRoute?.params?.maincategory) {
      const item = collections?.find(
        (item) =>
          router?.currentRoute?.params?.maincategory ===
          slugify(item.id, { lower: true })
      );
      setFieldValue("collection", item);
      setSelected(item?.id);
      const regexPromotions = /providers$/g;

      if (router?.currentRoute?.params?.subcategory) {
        const subitem = item?.children?.find(
          (item) =>
            router?.currentRoute?.params?.subcategory ===
            slugify(item.id, { lower: true })
        );
        setSubCategory(item?.id, subitem?.id);
      } else if (router?.pathname?.match(regexPromotions)) {
        setFieldValue("collection", {
          id: "providers",
          originalId: "providers",
        });
        setSelected("providers");
      } else {
        setSubCategory(item?.id, item?.children?.[0]?.id);
      }
    } else {
      setFieldValue("collection", collections?.[0]);
      setSelected(collections?.[0]?.id);
      //setSubCategory(collections?.[0]?.id, collections?.[0]?.children?.[0]?.id);
    }
  }, [router?.currentRoute?.name, collections?.length]);

  let newCollection = null;
  let restOfCollection = [];
  if (selected === "home" || !selected) {
    let tournamentItem = { id: "home|tournament", label: "Tournaments" };
    let selectedColl = collections?.find((coll) => coll.id === "home");

    console.log("selectedColl", selectedColl);

    let collectionChildren = selectedColl?.children ?? [];

    console.log("collectionChildren", collectionChildren);

    newCollection = [
      ...collectionChildren?.slice(0, 4),
      tournamentItem,
      ...collectionChildren?.slice(4, collectionChildren?.length),
    ];
    restOfCollection = collections?.filter((coll) => coll.id !== "home") ?? [];
  }
  console.log("collections", collections);
  console.log("selected", selected);
  console.log("newCollection", newCollection);
  console.log("restOfCollection", restOfCollection);

  const [updateCollections, setUpdateCollections] = useState(
    newCollection
      ? [...restOfCollection, { id: "home", children: newCollection }]
      : collections
  );

  useEffect(() => {
    if (selected === "home") {
      let tournamentItem = { id: "home|tournament", label: "Tournaments" };
      let selectedColl = collections?.find((coll) => coll.id === "home");
      let collectionChildren = selectedColl?.children;
      let newCollection = [
        ...collectionChildren?.slice(0, 4),
        tournamentItem,
        ...collectionChildren?.slice(4, collectionChildren?.length),
      ];
      let restOfCollection = collections?.filter((coll) => coll.id !== "home");

      setUpdateCollections([
        ...restOfCollection,
        { id: "home", children: newCollection },
      ]);
    } else {
      setUpdateCollections(collections);
    }
  }, [selected]);

  console.log("updateCollections", updateCollections);
  console.log("align", align);
  return (
    <Games>
      {({ recentGames }) => (
        <>
          {align === "center" && (
            <div className="py-10 lg:pt-20 lg:pb-14">
              <div className="px-3 text-center xl:container xl:px-28">
                <h2 className="mb-6 text-4xl font-light leading-none text-center text-white md:text-5xl ">
                  {intl.formatMessage({
                    defaultMessage: "Welcome to the Casino",
                    description: "Placeholder for the search input",
                  })}
                </h2>
                <div className="relative inline-block " id="search-navigation">
                  <Field
                    name="query"
                    placeholder={intl.formatMessage({
                      defaultMessage: "Search e.g. Starburst, Netent",
                      description: "Placeholder for the search input",
                    })}
                    type="text"
                    className="z-10 h-10 pl-6 text-white transition-all rounded-full w-72 focus:outline-none focus:border-gold focus:ring-0 focus:ring-offset-0 focus:ring-transparent focus:text-bold bg-brand-600"
                    autoComplete="off"
                    spellCheck="false"
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                  />
                  {(!query || query?.length === 0) && (
                    <Icon
                      icon="search"
                      className="absolute z-10 w-5 h-5 ml-5 text-brand-500 top-2 right-4 xl:block"
                    />
                  )}
                  {query?.length >= 1 && (
                    <button
                      className="search-clear"
                      type="button"
                      onClick={(e) => {
                        e.preventDefault();
                        setFieldValue("query", "");
                        setActiveField(false);
                        setFieldValue("activeSearch", false);
                        handleBlur();

                        handleSelectPreviousCollection();
                      }}
                    >
                      <Icon
                        icon="close"
                        className="absolute z-10 w-3 h-3 ml-5 text-brand-500 top-3.5 right-4 xl:block"
                      />
                    </button>
                  )}
                </div>
              </div>
            </div>
          )}
          <div
            className={cx(
              "z-20 xl:container md:mx-auto pr-0 w-full  flex flex-col transition-all duration-500 items-start justify-center md:mt-2 md:sticky sticky  bg-brand py-3 xl:px-28 px-3 md:top-26",
              isNavigationOpen ? " filter md:blur-sm " : "",
              {
                "top-26": scrollDirection !== "down" && isMobile.any,
                "-top-10": scrollDirection === "down" && isMobile.any,
              }
            )}
            id="game-browser-navigation"
          >
            <div
              className={cx(
                "inline-flex flex-row items-start justify-start max-w-full  px-3 overflow-auto bg-blue-darkest md:rounded md:justify-center md:overflow-visible md:px-0",
                { "text-center mx-auto ": align === "center" }
              )}
              id="main-menu"
            >
              <div
                className={cx(
                  "flex flex-row flex-1 mb-2 rtl:mr-0 rtl:ml-1 mr-1 overflow-x-auto"
                )}
              >
                <div
                  className={cx(
                    "relative h-11 px-5 mb-1 ltr:mr-3 rtl:ml-3 flex flex-1 items-center flex-shrink-0 justify-center xl:hidden rounded-3xl bg-brand-600 text-brand-500",
                    {
                      hidden: align === "center",
                    }
                  )}
                >
                  <Field
                    id="query"
                    name="query"
                    className={cx(
                      "transition-all duration-700 flex items-center justify-center whitespace-nowrap group relative bg-transparent z-20 border-none",
                      {
                        "w-64 pl-8 text-white": activeField,
                        "w-6 text-transparent": !activeField,
                      }
                    )}
                    autoComplete="off"
                    onFocus={handleFocus}
                    // onBlur={handleBlur}
                    spellCheck="false"
                  />
                  <div className="absolute z-20 w-6 h-6 top-3 left-5">
                    {(!query || query?.length === 0) && (
                      <button
                        className="search-clear"
                        onClick={(e) => {
                          setActiveField(true);
                          setFieldValue("activeSearch", true);
                        }}
                      >
                        <Icon
                          icon="search"
                          className="z-10 w-5 h-5 text-brand-500"
                        />
                      </button>
                    )}
                    {query?.length >= 1 && (
                      <button
                        className="search-clear"
                        type="button"
                        onClick={(e) => {
                          e.preventDefault();
                          setFieldValue("query", "");
                          setActiveField(false);
                          setFieldValue("activeSearch", false);
                          handleBlur();

                          handleSelectPreviousCollection();
                        }}
                      >
                        <Icon
                          icon="close"
                          className="z-10 w-5 h-5 text-brand-500"
                        />
                      </button>
                    )}
                  </div>
                </div>
                {collections?.map((item, i) => (
                  <Item
                    to={
                      item.id === "live"
                        ? "liveLobby"
                        : item.id === "crypto"
                        ? "cryptoLobby"
                        : "lobby"
                    }
                    params={{
                      lobby: "casino",
                      maincategory: slugify(item.id, { lower: true }),
                    }}
                    icon={i === 0 ? "home" : null}
                    label={
                      getCategoryLabel(item.id) ||
                      item.id?.charAt(0).toUpperCase() + item.id?.slice(1)
                      // labels?.[item.id] ??
                      // item.id?.charAt(0).toUpperCase() + item.id?.slice(1)
                    }
                    scroll={`#search-naviogaion`}
                    additionalScroll={true}
                    onClick={() => {
                      Promise.all([
                        setFieldValue("collection", item),
                        setFieldValue("query", ""),
                        setSelected(item?.id),
                        setSubCategory(item?.id, item?.children?.[0]?.id),
                        setSubSelected(item?.children?.[0]?.label),
                        router.setRoute(
                          item?.id === "live"
                            ? "liveLobby"
                            : item?.id === "crypto"
                            ? "cryptoLobby"
                            : "lobby"
                        ),
                      ]);
                    }}
                    trackName="Collection"
                    trackParams={{
                      component: "GameBrowserNavigation",
                      id: item.id,
                    }}
                    key={item.id}
                    id={`gambrowser-${item.id}`}
                    active={
                      (router?.routename !== "liveLobby" &&
                        item?.id === selected) ||
                      (router?.routename === "liveLobby" &&
                        item.id === "live") ||
                      (router?.routename === "cryptoLobby" &&
                        item.id === "crypto")
                    }
                  />
                ))}
                <Item
                  to={"lobby"}
                  params={{ lobby: "casino", maincategory: "providers" }}
                  label={intl.formatMessage({
                    defaultMessage: "Providers",
                    description: "label",
                  })}
                  onClick={() => {
                    setFieldValue("collection", {
                      id: "providers",
                      originalId: "providers",
                    });
                    setFieldValue("activeSearch", false);
                    setSelected("providers");
                    // setSubCategory(providerItem?.id, providerItem?.children?.[0]?.id),
                    router.setRoute("lobby");
                  }}
                  active={selected === "providers"}
                  trackName="Collection"
                  trackParams={{
                    component: "GameBrowserNavigation",
                    id: "Providers",
                  }}
                />
              </div>
              {align !== "center" && (
                <div className="relative">
                  <Field
                    name="query"
                    placeholder={intl.formatMessage({
                      defaultMessage: "Search e.g. Starburst, Netent",
                      description: "Placeholder for the search input",
                    })}
                    type="text"
                    className="z-10 hidden h-10 pl-6 text-white transition-all rounded-full rtl:pr-16 w-72 xl:block focus:outline-none focus:border-gold focus:ring-0 focus:ring-offset-0 focus:ring-transparent focus:text-bold bg-brand-600"
                    autoComplete="off"
                    spellCheck="false"
                    onFocus={handleFocus}
                    // onBlur={handleBlur}
                  />
                  {!activeField && (
                    <Icon
                      icon="search"
                      className="absolute z-10 hidden w-5 h-5 ml-5 text-white top-2 right-4 xl:block"
                    />
                  )}
                  {activeField && (
                    <button
                      className="search-clear"
                      type="button"
                      onClick={(e) => {
                        e.preventDefault();
                        setFieldValue("query", "");
                        setActiveField(false);
                        setFieldValue("activeSearch", false);
                        handleBlur();

                        handleSelectPreviousCollection();
                      }}
                    >
                      <Icon
                        icon="close"
                        className="absolute z-10 hidden w-3 h-3 ml-5 text-white top-3.5 right-4 xl:block"
                      />
                    </button>
                  )}
                </div>
              )}
            </div>

            {selected !== "all" && (
              <>
                {updateCollections?.map(
                  (item, i) =>
                    collection.id === item.id && (
                      <div
                        className={cx(
                          "w-full px-3 pb-1 overflow-auto top-14 md:px-0",
                          {
                            "text-center": align === "center",
                          }
                        )}
                      >
                        <div
                          className={cx("inline-flex flex-row flex-1 mx-auto ")}
                        >
                          {item?.children?.map(
                            (subcategory, c) =>
                              ((subcategory.id === "home|recently_played" &&
                                recentGames?.length > 0) ||
                                subcategory.id !== "home|recently_played") && (
                                <Item
                                  to="lobby"
                                  params={{
                                    lobby: "casino",
                                    maincategory: slugify(item.id, {
                                      lower: true,
                                    }),
                                    subcategory: slugify(subcategory.label, {
                                      lower: true,
                                    }),
                                  }}
                                  id={subcategory.id}
                                  scroll={() => {
                                    const [id] =
                                      subcategory?.id?.split(":") ?? [];
                                    const [filterGroup] =
                                      id?.split?.("~") ?? [];

                                    console.log(
                                      "renderanchor",
                                      `#subcategory-${filterGroup.replace(
                                        "|",
                                        "-"
                                      )}`
                                    );
                                    return `#subcategory-${filterGroup.replace(
                                      "|",
                                      "-"
                                    )}`;
                                  }}
                                  label={
                                    getCategoryLabel(subcategory.id) ||
                                    subcategory.label
                                    // labels?.[subcategory.id] ??
                                    // subcategory.label
                                  }
                                  subcategory
                                  trackName="Sub Collection"
                                  trackParams={{
                                    component: "GameBrowserNavigation",
                                    id: subcategory.id,
                                  }}
                                  onClick={() => {
                                    setSubSelected(subcategory?.label),
                                      setSubCategory(item?.id, subcategory?.id);
                                  }}
                                  key={subcategory.id}
                                  active={subcategory.id === subSelected}
                                />
                              )
                          )}
                        </div>
                      </div>
                    )
                )}
              </>
            )}
          </div>
        </>
      )}
    </Games>
  );
};
export default GameBrowserNavigation;
