import React, { useEffect, useState } from "react";
// import CentrifugeReact from "../../../../core/centrifuge";
import cx from "classnames";
import { useIntl } from "react-intl";
// import useSWR from "swr";
// import useSWRImmutable from "swr/immutable";
import GameItem from "../../GameItem";
import Button from "@components/Button";
// import { useCentrifuge } from "@components/Provide/CentrifugeContext";
import Games from "../../Games";

import ProvidersList from "../ProvidersList/ProvidersList";

import getTournamentsQuery from "../../../Tournaments/get-tournaments.graphql";
import { useLazyQuery, useQuery } from "@apollo/client";
import LandingCasinoCategory from "./LandingCasinoCategory";
import getSoftSwissGamesByIdsQuery from "../../get-softswiss-games-by-ids.graphql";
import useSWR from "swr";
import { getRecentGames } from "../../../../data/model/casino";
import { useAuth } from "../../../Provide/AuthContext";
import useSession from "../../../../hooks/useSession";
import { getPlayerDetails } from "../../../../data/model/profile";

const LandingCasino = ({
  collection,
  collections,
  selected,
  subSelected,
  setSubSelected,
}) => {
  const intl = useIntl();
  const { isAuthenticated } = useAuth();
  const [updateCollections, setUpdateCollections] = useState(collections);
  const { userCountry } = useSession();

  const { data: playerDetails } = useSWR(
    isAuthenticated ? "/api/player" : null,
    getPlayerDetails,
    { refreshInterval: 60000 }
  );
  const {
    loading: loadingTournaments,
    error: errorAllTournaments,
    data: allTournaments,
  } = useQuery(getTournamentsQuery, {
    ssr: false,
    variables: {
      locale: intl?.locale,
      statuses:
        isAuthenticated && playerDetails && playerDetails?.statuses
          ? playerDetails?.statuses.map((x) => x?.id)
          : [],
    },
  });

  const [
    getGamesByIds,
    { loading: loadingGetGamesByIds, data: getGamesByIdsData },
  ] = useLazyQuery(getSoftSwissGamesByIdsQuery, {
    onCompleted: (data) => {
      // console.log("recent--played--games", data);
    },
  });

  const { data: recentGamesWithoutCurrFilter = [] } = useSWR(
      isAuthenticated ? "getRecentGames" : null,
      (url) => getRecentGames()
  );

  useEffect(() => {
    if (isAuthenticated && recentGamesWithoutCurrFilter?.length > 0) {
      getGamesByIds({
        variables: {
          gameIds: recentGamesWithoutCurrFilter?.map((x) => x.identifier),
          isAuthenticated,
          userCountry
        },
      });
    }
  }, [recentGamesWithoutCurrFilter, isAuthenticated]);

  console.log("getGamesByIdsData collections", collections, selected);

  useEffect(() => {
    if (selected === "home") {
      let tournamentItem = { id: "home|tournament", label: "Tournaments" };
      let selectedColl = collections?.find((coll) => coll.id === "home");
      let collectionChildren = selectedColl?.children;
      let newCollection = [
        ...collectionChildren?.slice(0, 4),
        tournamentItem,
        ...collectionChildren?.slice(4, collectionChildren?.length),
      ];
      let restOfCollection = collections?.filter((coll) => coll.id !== "home");

      setUpdateCollections([
        ...restOfCollection,
        { id: "home", children: newCollection },
      ]);
    } else {
      setUpdateCollections(collections);
    }
  }, [selected, allTournaments]);

  //to remove providers which are empty
  // const refilterProvidersList = () => {
  //   const providersWithGames = [];
  //   providerslist?.map((provider) => {
  //     const providersList = [];
  //     const provs = allgames?.map((item) => {
  //       item?.provider?.toLowerCase() === provider?.id?.toLowerCase() &&
  //         item.real[currency] &&
  //         providersList.push(item);
  //     });

  //     if (providersList?.length > 0) {
  //       providersWithGames.push(provider);
  //     }
  //   });
  //   console.log("providersWithGames", providersWithGames);
  //   setProvidersWithGames(providersWithGames);
  // };

  // useEffect(() => {
  //   if (allgames.length > 0) {
  //     refilterProvidersList();
  //   }
  // }, [allgames, currency, selected]);



  // console.log('updateCollections?.find((item) => item.id === collection.id)',updateCollections);


  return (
    <>
      {!collection?.children && <LandingCasinoCategory selected={selected} subSelected={subSelected} collection={collection}
                             collections={updateCollections}
                             subcategory={collection}
                             setSubSelected={setSubSelected}
                             allTournaments={allTournaments}
                             recentGames={[]}
      />}
      {/*<Games selected={selected} subSelected={collection}>*/}
      {/*  {({ getGroup }) => {*/}
      {/*    const mainGames = getGroup("Casino", `${collection}`);*/}
      {/*    if (mainGames.length === 0 || collection?.children?.length > 0) {*/}
      {/*      console.log("blanck fire");*/}
      {/*      return <div />;*/}
      {/*    }*/}

      {/*    return (*/}
      {/*      <>*/}
      {/*        <h3 className="font-light text-white text-24 flex flex-1 capitalize mt-7.5">*/}
      {/*          {*/}
      {/*            labels?.[*/}
      {/*              updateCollections?.find((item) => item.id === collection.id)*/}
      {/*                ?.id*/}
      {/*            ]*/}
      {/*          }*/}
      {/*        </h3>*/}
      {/*        <div className="grid grid-cols-2 md:grid-cols-5 gap-2.5 md:gap-8 mt-5">*/}
      {/*          {mainGames?.map((item) => (*/}
      {/*            <>*/}
      {/*              <GameItem*/}
      {/*                // jackpot={jackpots?.[currency]?.[item.id]}*/}
      {/*                game={item}*/}
      {/*                key={item.id}*/}
      {/*              />*/}
      {/*            </>*/}
      {/*          ))}*/}
      {/*        </div>*/}
      {/*      </>*/}
      {/*    );*/}
      {/*  }}*/}
      {/*</Games>*/}
      {selected === "providers" ? (
        <ProvidersList />
      ) : (
        <>
          {updateCollections
            ?.find((item) => item.id === collection.id)
            ?.children?.map((subcategory) => (<LandingCasinoCategory key={subcategory?.id} selected={selected} subSelected={subSelected} collection={collection}
                                                                     collections={updateCollections}
                                                                     subcategory={subcategory}
                                                                     setSubSelected={setSubSelected}
                                                                     allTournaments={allTournaments}
                                                                     recentGames={getGamesByIdsData?.getSoftSwissGamesByIds}
                                                                    />

            ))}
        </>
      )}
    </>
  );
};
export default LandingCasino;
