import React, { useCallback, useEffect, useState } from "react";
// import CentrifugeReact from "../../../../core/centrifuge";
import cx from "classnames";
import { useIntl } from "react-intl";
// import useSWR from "swr";
// import useSWRImmutable from "swr/immutable";
import Button from "@components/Button";
import { useQuery } from "@apollo/client";
import InfiniteScroll from "react-infinite-scroller";
import GameItem from "../../GameItem";
// import { useCentrifuge } from "@components/Provide/CentrifugeContext";
import Games from "../../Games";
import { useDevice } from "../../../Provide/DeviceContext";
import // getAllowedGames,
// getJackpots,
// getProviders,
// getTotalJackpots,
"../../../../data/model/casino";
import { useRouter } from "../../../Provide/RouterContext";
import ProvidersList from "../ProvidersList/ProvidersList";
import { useWallet } from "../../../Provide/WalletContext";
// import Cookies from "universal-cookie";
import FeaturedTournament from "../../../Tournaments/FeaturedTournament";
import Icon from "../../../Icon/Icon";
import GET_TRANSLATED_CATEGORIES from "./get-translated-categories.graphql";

const Header = ({
  collection,
  collections,
  subcategory,
  setSubSelected,
  subSelected,
  selected,
  ...props
}) => {
  const intl = useIntl();

  const { loading, error, data } = useQuery(GET_TRANSLATED_CATEGORIES, {
    variables: { locale: intl.locale },
  });

  const getCategoryLabel = useCallback(
    (feCategoryID) => {
      const translatedCategory = data?.getTranslatedCategories?.find(
        (x) => x.feCategoryID === feCategoryID
      );
      return translatedCategory?.translatedCategory
        ? translatedCategory?.translatedCategory
        : "";
    },
    [data]
  );

  const labels = {
    home: intl.formatMessage({
      defaultMessage: "Casino Lobby",
      description: "Label",
    }),
    slots: intl.formatMessage({
      defaultMessage: "Slots",
      description: "Label",
    }),
    live: intl.formatMessage({
      defaultMessage: "Live Casino",
      description: "Label",
    }),
    table: intl.formatMessage({
      defaultMessage: "Table Games",
      description: "Label",
    }),
    "home|dropsandwins": intl.formatMessage({
      defaultMessage: "Drops & Wins",
      description: "Label",
    }),
    "home|recommended": intl.formatMessage({
      defaultMessage: "Recommended",
      description: "Label",
    }),
    "home|trending": intl.formatMessage({
      defaultMessage: "Trending",
      description: "Label",
    }),
    "home|recently_played": intl.formatMessage({
      defaultMessage: "Recently Played",
      description: "Label",
    }),
    "home|gamesofthemonth": intl.formatMessage({
      defaultMessage: "Games of the Month",
      description: "Games of the Month Label",
    }),
    "home|gamesofthemonth~s2": intl.formatMessage({
      defaultMessage: "Games of the Month",
      description: "Games of the Month Label",
    }),
    "home|new": intl.formatMessage({
      defaultMessage: "Latest Releases",
      description: "Label",
    }),
    "home|featured_slots": intl.formatMessage({
      defaultMessage: "Featured Slots",
      description: "Label",
    }),
    "home|tournament": intl.formatMessage({
      defaultMessage: "Tournaments Menu",
      description: "Label",
    }),
    "home|classic_live": intl.formatMessage({
      defaultMessage: "Classic Live Games",
      description: "Label",
    }),
    "home|jackpot": intl.formatMessage({
      defaultMessage: "Jackpot Games",
      description: "Label",
    }),
    "home|lottery": intl.formatMessage({
      defaultMessage: "Lottery",
      description: "Label",
    }),
    "live|popular": intl.formatMessage({
      defaultMessage: "Popular Live Games",
      description: "Label",
    }),
    "live|roulette": intl.formatMessage({
      defaultMessage: "Roulette",
      description: "Label",
    }),
    "live|blackjack": intl.formatMessage({
      defaultMessage: "Blackjack",
      description: "Label",
    }),
    "live|gameshows": intl.formatMessage({
      defaultMessage: "Game Shows",
      description: "Label",
    }),
    "live|baccarat": intl.formatMessage({
      defaultMessage: "Baccarat",
      description: "Label",
    }),
    "live|poker": intl.formatMessage({
      defaultMessage: "Poker",
      description: "Label",
    }),
    "live|dice": intl.formatMessage({
      defaultMessage: "Dice Games",
      description: "Label",
    }),
    "live|all": intl.formatMessage({
      defaultMessage: "All Live",
      description: "Label",
    }),
    "live|card": intl.formatMessage({
      defaultMessage: "Card Games",
      description: "Label",
    }),
    "slots|recommended": intl.formatMessage({
      defaultMessage: "Recommended Slots",
      description: "Label",
    }),
    "slots|popular": intl.formatMessage({
      defaultMessage: "Popular Slots",
      description: "Label",
    }),
    "slots|megaways": intl.formatMessage({
      defaultMessage: "Megaways",
      description: "Label",
    }),
    "slots|classic": intl.formatMessage({
      defaultMessage: "Classic slots",
      description: "Label",
    }),
    "slots|jackpots": intl.formatMessage({
      defaultMessage: "Jackpots",
      description: "Label",
    }),
    "slots|drops_and_wins": intl.formatMessage({
      defaultMessage: "Drops and Wins",
      description: "Label",
    }),
    "slots|all": intl.formatMessage({
      defaultMessage: "All Slots",
      description: "Label",
    }),
    "table|roulette": intl.formatMessage({
      defaultMessage: "Roulette",
      description: "Label",
    }),
    "table|blackjack": intl.formatMessage({
      defaultMessage: "Blackjack",
      description: "Label",
    }),
    "table|baccarat": intl.formatMessage({
      defaultMessage: "Baccarat",
      description: "Label",
    }),
    "table|video_poker": intl.formatMessage({
      defaultMessage: "Video Poker",
      description: "Label",
    }),
    all: intl.formatMessage({
      defaultMessage: "All games",
      description: "Label",
    }),
    "crypto|Bitcoin": intl.formatMessage({
      defaultMessage: "Bitcoin Games",
      description: "Label",
    }),
    "crypto|ethereum": intl.formatMessage({
      defaultMessage: "Ethereum Games",
      description: "Label",
    }),
    "crypto|Livecrypto": intl.formatMessage({
      defaultMessage: "Live Crypto",
      description: "Label",
    }),
    "crypto|Litecoin": intl.formatMessage({
      defaultMessage: "Litecoin Games",
      description: "Label",
    }),
  };

  const coll = collections?.filter((item) => collection.id === item.id);
  const collChild = coll[0]?.children?.[0]?.id;
  let current = collChild;
  useEffect(() => {
    setSubSelected(collChild);
    window.addEventListener("scroll", scrollingThroughSections);
  }, [selected]);

  function scrollingThroughSections() {
    const sections = document.querySelectorAll("section");
    sections.forEach((section) => {
      const sectionTop = section.offsetTop - 225;
      const sectionHeight = section.clientHeight;
      if (pageYOffset >= sectionTop) {
        current = section.getAttribute("id");
      }
    });
    if (current === null) {
      setSubSelected(collChild);
    } else {
      setSubSelected(current);
    }
  }

  const [id] = subcategory?.id?.split(":") ?? [];
  const [filterGroup] = id?.split?.("~") ?? [];

  return (
    <>
      <a id={`subcategory-${filterGroup.replace("|", "-")}`} />
      {subcategory.id === "home|tournament" ? (
        <>
          <div className="flex flex-row mt-7.5 gap-1 items-center">
            <Icon className="w-6 h-6 text-gold " icon="noBGMedal" />
            <h3 className="flex flex-1 font-semibold capitalize text-gold text-24 ">
              {getCategoryLabel(subcategory.id) ||
                (labels?.[subcategory.id] ?? subcategory.label)}
            </h3>
          </div>
        </>
      ) : (
        <h3 className="font-light text-white text-24 flex flex-1 capitalize mt-7.5">
          {getCategoryLabel(subcategory.id) ||
            (labels?.[subcategory.id] ?? subcategory.label)}
        </h3>
      )}
    </>
  );
};

export default Header;
