import { useQuery } from "@apollo/client";
import React, { useState, useRef, useEffect } from "react";
import { useIntl } from "react-intl";
import useSWR from "swr";
import cx from "classnames";
import slugify from "slugify";
import Button from "../Button";
import Icon from "../Icon";
import Link from "../Link";
import getFeaturedTournamentsQuery from "./get-featured-tournament.graphql";
import Slider from "../Slider";
import { getAllTournaments } from "../../data/model/tournament";
import Tournament from "./Templates/Tournament";
import { useDevice } from "../Provide/DeviceContext";

const FeaturedTournament = ({ allTournaments }) => {
  const intl = useIntl();
  const { loading, error, data } = useQuery(getFeaturedTournamentsQuery, {
    variables: { locale: intl?.locale },
  });
  const [tournamentList, setTournamentList] = useState([]);
  // const { loading: loadingTournaments, error: errorAllTournaments, data: allTournaments } = useQuery(getTournamentsQuery, { variables: { locale: intl?.locale }});
  const { data: tournaments } = useSWR("getAllTournaments", getAllTournaments);

  const [currentSlide, setCurrentSlide] = useState(0);
  const slider = useRef();
  const { isMobile } = useDevice();

  useEffect(() => {
    const objectArray = [];
    if (allTournaments && tournaments) {
      const activeTournaments = allTournaments?.Tournaments?.filter(
        (tour) => tour?.active
      );

      activeTournaments?.map((item) => {
        const tournament = tournaments?.find(
          (cockpitItem) => item?.id === cockpitItem?.title
        );

        tournament &&
          objectArray.push({
            id: slugify(item?.id ?? tournament.id, { lower: true }),
            tournamentIDNumber: tournament.id,
            slug: slugify(item?.id ?? tournament.id, { lower: true }),
            title: item?.title,
            description: item?.content,
            thumbnail: item?.promoBlock?.path,
            startdate: tournament?.start_at,
            enddate: tournament?.end_at,
            imageBlock: item?.widgetBlockCta,
          });
      });
    }
    setTournamentList(objectArray);
  }, [data, tournaments]);

  const options = {
    duration: 300,
    loop: true,
    draggable: true,
    autostart: false,
    perPage: 1,
    gap: 0,
    delay: 2000,
  };

  const sliderDotClick = (index) => {
    slider?.current?.goTo(index);
  };

  return (
    <div>
      {!isMobile?.any ? (
        <div
          className="relative w-full mt-6 bg-cover h-96"
          style={{
            maxHeight: "40rem",
            minHeight: "40rem",
            backgroundImage: `url(${COCKPITCFG.url}/${data?.FeaturedTournament?.thumbnail?.path})`,
          }}
        >
          <div className="z-10 flex flex-row w-full h-full">
            <div className="flex flex-col items-center justify-center w-1/2 h-full px-10">
              <Icon className="w-16 h-16 text-gold" icon="noBGMedal" />
              <h2 className="mt-4 text-3xl font-light text-white md:text-6xl">
                {data?.FeaturedTournament?.title}
              </h2>
              <div
                className="my-3 font-light"
                dangerouslySetInnerHTML={{
                  __html: data?.FeaturedTournament?.content,
                }}
              />
              <Link
                trackName="Featured Tournament"
                trackParams={{ component: "FeaturedTournament" }}
                to="tournaments"
                className="mt-4 text-lg text-black"
              >
                <Button.Action arrow="right" className="w-full">
                  {intl.formatMessage({
                    defaultMessage: "See all prize tournaments",
                    description: "Button label",
                  })}
                </Button.Action>
              </Link>
            </div>
            <div className="flex flex-col justify-center w-1/2 pr-16">
              <div className="w-full md:overflow-hidden rounded-xl">
                <div
                  className={cx("w-full md:-mb-2 bg-cover bg-black md:mb-8")}
                >
                  <div
                    className={cx(
                      "relative md:w-max-96 w-full mx-auto md:shadow-bannerShadow bg-black md:relative"
                    )}
                  >
                    <div className="w-full aspect-w-16 aspect-h-10 md:aspect-none">
                      <div className="absolute top-0 bottom-0 left-0 right-0">
                        <Slider
                          onChange={() =>
                            setCurrentSlide(slider?.current?.currentSlide)
                          }
                          {...options}
                          ref={slider}
                          className="h-full"
                        >
                          {tournamentList.map((item, index) => (
                            <Tournament item={item} slug={item?.slug} />
                          ))}
                        </Slider>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {tournamentList.length > 1 && (
                <div className="flex flex-row items-center justify-center w-full mt-5 transition-all duration-500 ease-in-out cursor-pointer">
                  <span
                    onClick={() => slider?.current?.next()}
                    className={cx(
                      " transition-all ease-in-out duration-500 text-white font-light mr-4",
                      { "text-brand-400": currentSlide === 0 }
                    )}
                  >
                    {intl.formatMessage({
                      defaultMessage: "Prev",
                      description: "Label",
                    })}
                  </span>
                  {tournamentList?.length > 1 &&
                    tournamentList?.map((slide, index) => (
                      <div
                        className={cx(
                          "rounded-full mx-1 h-4 w-4 hover:bg-gold transition-all ease-in-out duration-500 ",
                          { "bg-gold": index === currentSlide },
                          { "bg-brand-400": index !== currentSlide }
                        )}
                        onClick={() => {
                          setCurrentSlide(index);
                          sliderDotClick(index);
                        }}
                      />
                    ))}
                  <span
                    onClick={() => slider?.current?.next()}
                    className={cx(
                      " transition-all ease-in-out duration-500 text-white font-light ml-4",
                      {
                        "text-brand-400":
                          currentSlide === tournamentList?.length - 1,
                      }
                    )}
                  >
                    {intl.formatMessage({
                      defaultMessage: "Next",
                      description: "Label",
                    })}
                  </span>
                </div>
              )}
            </div>
          </div>
        </div>
      ) : (
        <div
          className="relative w-full mt-3 rounded h-96 md:mt-6"
          style={{ maxHeight: "40rem", minHeight: "35rem" }}
        >
          <div className="z-10 flex flex-row w-full h-full">
            <div className="flex flex-col justify-center w-full ">
              <div className="w-full md:overflow-hidden rounded-xl">
                <div
                  className={cx("w-full md:-mb-2 bg-cover bg-black md:mb-8")}
                >
                  <div
                    className={cx(
                      "relative md:w-max-96 w-full mx-auto md:shadow-bannerShadow bg-black md:relative"
                    )}
                  >
                    <div className="w-full aspect-w-16 aspect-h-10 md:aspect-none">
                      <div className="top-0 bottom-0 left-0 right-0 lg:absolute">
                        <Slider
                          onChange={() =>
                            setCurrentSlide(slider?.current?.currentSlide)
                          }
                          {...options}
                          ref={slider}
                          className="h-full"
                        >
                          {tournamentList.map((item, index) => (
                            <Tournament item={item} slug={item?.slug} />
                          ))}
                        </Slider>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex flex-row items-center justify-center w-full transition-all duration-500 ease-in-out cursor-pointer 3xs:mt-64 2xs:mt-60 sm:mt-5">
                {tournamentList?.length > 1 &&
                  tournamentList?.map((slide, index) => (
                    <div
                      className={cx(
                        "rounded-full mx-1 h-4 w-4 hover:bg-gold transition-all ease-in-out duration-500 ",
                        { "bg-gold": index === currentSlide },
                        { "bg-brand-400": index !== currentSlide }
                      )}
                      onClick={() => {
                        setCurrentSlide(index);
                        sliderDotClick(index);
                      }}
                    />
                  ))}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default FeaturedTournament;
