import React, { useState } from "react";
import { Form, Formik } from "formik";
import { useQuery } from "@apollo/client";
import { useSelector } from "react-redux";
import cx from "classnames";
import { useIntl } from "react-intl";
import GameBrowserNavigation from "./GameBrowserNavigation";
import SearchResults from "./Templates/SearchResults";
import LandingCasino from "./Templates/LandingCasino";
import { useAuth } from "../Provide/AuthContext";
import getCollectionsQuery from "./GameBrowserNavigation/get-collections.graphql";

const GameBrowserComponent = ({
  section = "LandingCasino",
  menu = "default",
  align = "left",
}) => {
  const intl = useIntl();
  const { isAuthenticated } = useAuth();
  const isNavigationOpen = useSelector(
    (state) => state.navigation.isNavigationOpen
  );
  const [selected, setSelected] = useState(null);
  const [subSelected, setSubSelected] = useState(0);
  const {
    loading,
    error,
    data: collections,
  } = useQuery(getCollectionsQuery, {
    variables: {
      locale: intl.locale,
    },
  });
  // const {data: providers} = useSWR('getProviders', getProviders())

  const Sections = {
    LandingCasino,
  };

  return (
    <>
      <Formik
        initialValues={{
          section,
          query: "",
          collection: { id: "home", originalId: "home" },
          activeSearch: false,
        }}
      >
        {({
          values: { query, section, collection, activeSearch },
          isSubmitting,
          status,
          isValid,
          setFieldValue,
        }) => {
          const GridComponent = activeSearch
            ? SearchResults
            : Sections[section];
          // const GridProviderComponent = activeProviderSearch ? SearchProviderResults : Sections[section];

          const processTree = (items) =>
            items?.map((category) => {
              let extraOptions = { size: "s3" };

              switch (true) {
                case category.fullId.indexOf("s2") > 1:
                  extraOptions = { size: "s2" };
                  break;
                case category.fullId.indexOf("s3") > 1:
                  extraOptions = { size: "s3" };
                  break;
                case category.fullId.indexOf("s4") > 1:
                  extraOptions = { size: "s4" };
                  break;
              }
              const [id, ...options] = category.id.split(":");
              if (category?.children) {
                return {
                  ...category,
                  id,
                  ...extraOptions,
                  originalId: category.id,
                  children: processTree(category?.children),
                };
              }
              return {
                ...category,
                id,
                ...extraOptions,
                originalId: category.id,
              };
            });

          const categoryTree = processTree(collections?.getCollections?.items);

          return (
            <div className="min-h-screen pt-6 bg-brand">
              <div id="prevGameBrowser" />
              <GameBrowserNavigation
                setFieldValue={setFieldValue}
                collections={categoryTree}
                collection={collection}
                // providers={providers?.getProviders?.items}
                selectedSection="Landing"
                selected={selected}
                setSelected={setSelected}
                subSelected={subSelected}
                setSubSelected={setSubSelected}
                align={align}
                query={query}
              />
              <Form>
                <div
                  className={cx(
                    "flex flex-col xl:container mt-0 mb-8  md:my-0 px-3 xl:px-28 w-full md:mx-auto",
                    isNavigationOpen ? "filter blur-sm " : ""
                  )}
                  id="gameBrowser"
                >
                  <GridComponent
                    setFieldValue={setFieldValue}
                    collections={categoryTree}
                    collection={collection}
                    query={query}
                    activeSearch={activeSearch}
                    section={section}
                    selected={selected}
                    setSelected={setSelected}
                    subSelected={subSelected}
                    setSubSelected={setSubSelected}
                    key={selected}
                  />
                </div>
              </Form>
            </div>
          );
        }}
      </Formik>
    </>
  );
};

export default GameBrowserComponent;
