import React from "react";
import LandingOffer from "@components/LandingOffer";
import GameBrowser from "@components/GameBrowser";

const HomePublic = () => (
  <>
    <LandingOffer />
    <GameBrowser section="LandingCasino" menu="default" />
    {/* <Tournament /> */}
    {/* <Games> */}
    {/*  {({ getGroup }) => { */}
    {/*    const list = getGroup("Casino", "most-popular"); */}
    {/*    if (list?.length === 0) return null; */}
    {/*    return ( */}
    {/*      list?.length > 0 && ( */}
    {/*        <GridSlider */}
    {/*          header="Mest vindende Spilleautomater" */}
    {/*          list={list} */}
    {/*          label="Vis alle" */}
    {/*        /> */}
    {/*      ) */}
    {/*    ); */}
    {/*  }} */}
    {/* </Games> */}
    {/* <GridSlider /> */}
    {/* <Inspiration /> */}
  </>
);

export default HomePublic;
